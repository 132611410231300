.top-button {
    width: 100%;
    & a {
        &:first-child {
            margin-top: 0;
        }
        margin-top: 30px;
    }
}

.error {
    margin-top: 20px;
    text-align: center;
    height: 30px;
    font-family: TTNormRegular, sans-serif;
    font-size: 14px;
}

.bottom-button {
    width: 100%;
    margin-top: 60px;
}

@media (max-height: 569px) {
    .bottom-button {
        margin-top: 50px;
    }
}

.link-logout {
    position: relative;
    font-family: TTNormRegular, sans-serif;
    font-size: 20px;
    text-decoration: none;
    color: #5f5ff8;
    display: block;
    margin: 30px 0;
    text-align: center;
}