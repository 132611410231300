input[type="file"] {
  display: none;
}

.active-icon {
  width: 25px;
  height: 25px;
  &_wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #eeeeee;
    padding: 10px;
    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
  &_label {
    margin-bottom: 10px;
    max-width: 50px;
  }
}