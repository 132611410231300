.update-item {
  width: min(100%, 450px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 0 5px;
  & > .delete-btn {
    font-family: TTNormRegular, sans-serif;;
    font-size: 16px;
    color: white;
    background-color: #ef3939;
    outline: none;
    border: 1px solid transparent;
    padding: 3px 6px;
    border-radius: 5px;
    min-width: 88px;
  }
  &-buttons-wrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
    & > button {
      font-family: TTNormRegular, sans-serif;;
      font-size: 16px;
      color: white;
      background-color: #73C120;
      outline: none;
      border: 1px solid transparent;
      padding: 3px 6px;
      border-radius: 5px;
      min-width: 88px;
      &.disabled {
        background-color: #b2b2b2;
      }
    }
  }
}


