.search-input {
    margin-top: 10px;
    margin-bottom: 0;
    -webkit-appearance: none;
}

.is-find {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.search-block {
    margin-bottom: 20px;
    position: relative;
}

.search-item {
    display: block;
    margin: 0;
    text-decoration: none;
    color: black;
    font-family: TTNormRegular, sans-serif;
    font-size: 18px;
    padding: 10px 10px 10px 10px;
    border: 1px solid #73c120;
    &:hover {
        cursor: pointer;
    }
    &:last-child {
        border-radius: 0 0 10px 10px;
    }
}

.search-label {
    font-family: TTNormRegular, sans-serif;
    font-size: 17px;
}

.error-message {
    display: block;
    margin-top: 4px;
    color: red;
    font-family: TTNormRegular, sans-serif;
    font-size: 14px;
}

.invalid {
    border: 1px solid red;
    &:focus {
        border: 1px solid red;
    }
}

.MuiAutocomplete-popper {
    border-radius: 10px;
    box-shadow: 0 4px 7px rgba(0, 0, 0, 0.25);
    background: rgba(255, 255, 255, 0.9);
    padding: 0 0;
    font-size: 100%;
    overflow: hidden;
    width: 300px;
    position: absolute;
}

.MuiAutocomplete-listbox {
    padding: 0 0;
    & .MuiAutocomplete-option {
        display: block;
        margin: 0;
        text-decoration: none;
        color: black;
        font-family: TTNormRegular, sans-serif;
        font-size: 18px;
        padding: 10px 10px 10px 10px;
        border: 1px solid #73c120;
        &:hover {
            cursor: pointer;
        }
        &:last-child {
            border-radius: 0 0 10px 10px;
        }
    }
}

.MuiAutocomplete-noOptions {
    display: none;
}

.helper_wrapper {
    position: absolute;
    display: flex;
    align-items: center;
    bottom: 5px;
    right: 10px;
}