.notification-card {
  &-unread {
    background-color: #73C120;
  }
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 0 4px 7px;
  padding: 5px 16px;
  &:hover {
    cursor: pointer;
  }
}