.information-alert {
  &-wrapper {
    width: min(100%, 430px);
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    gap: 10px;
    height: 77px;
    padding: 15px;
    border-radius: 20px;
    background-color: white;
    box-shadow: 0 4px 7px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    z-index: 100;
    &-main {
      position: relative;
      display: flex;
      flex-shrink: 0;
    }
    & div p {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  &-button {
    position: absolute;
    height: 77px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    box-shadow: 0 4px 7px rgba(0, 0, 0, 0.25);
    flex-shrink: 0;
    border-radius: 20%;
  }
}