@font-face {
  font-family: 'TTNormBold';
  src: local('TTNormBold'), url(./fonts/TTNorms-Bold.woff) format('woff');
}

@font-face {
  font-family: 'TTNormRegular';
  src: local('TTNormRegular'), url(./fonts/TTNorms-Regular.ttf) format('truetype');
}

.container {
  min-height: 100vh;
  flex-direction: column;
  display: flex;
  align-items: center;
  width: min(100%, 450px);
  overflow-x: hidden;
  position: relative;
  margin: 0 auto;
  padding: 10px;
}

.wrap {
  height: 800px;
  max-height: 800px;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

h1 {
  font-family: TTNormBold, sans-serif;
  font-weight: bold;
  font-size: 26px;
  margin: 0 0 33px 0;
  text-align: center;
}

h3 {
  font-family: TTNormBold, sans-serif;
  font-weight: bold;
  font-size: 16px;
  margin: 0 0 0 0;
}

.logo {
  width: 110px;
  height: 160px;
  margin-bottom: 33px;
  margin-top: 120px;
}

.mt20 {
  margin-top: 20px;
}

p {
  white-space: pre-line;
}

@media (max-height: 825px) {
  .logo {
    margin-top: 80px;
  }
}

@media (max-height: 667px) {
  .logo {
    margin-top: 20px;
  }
}

@media (max-height: 568px) {
  .logo {
    margin-top: 10px;
  }
}

@media (max-width: 320px) {
  h1 {
    font-size: 20px;
  }
}

.default-form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

input[type=text] {
  display: block;
  font-family: TTNormRegular, sans-serif;
  font-size: 18px;
  padding: 0 10px;
  -webkit-appearance: none;
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border: 1px solid transparent;
  width: 100%;
  height: 48px;
  &:focus {
    outline: none;
    border: 1px solid #73c120;
  }
  &.invalid {
    border: 1px solid red;
  }
}

textarea {
  display: block;
  font-family: TTNormRegular, sans-serif;
  font-size: 18px;
  padding: 10px 10px;
  -webkit-appearance: none;
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border: 1px solid transparent;
  width: 100%;
  height: 150px;
  resize: none;
  &:focus {
    outline: none;
    border: 1px solid #73c120;
  }
}

.notification-message {
  width: 347px;
  font-size: 18px;
  word-break: break-word;
  margin-bottom: 10px;
  &.error {
    color: red;
  }
  &.success {
    color: #73c120;
  }
}

