.header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 30px;
    img {
        width: 44px;
        height: 65px;
    }
    p {
        font-family: TTNormRegular, sans-serif;
        font-size: 26px;
    }
    button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 54px;
        height: 54px;
        background-color: #73C120;
        border: none;
        border-radius: 50%;
        &:hover {
            cursor: pointer;
            opacity: 0.8;
        }
        img {
            width: 33px;
            height: 33px;
        }
    }
    &-logo {
        &:hover {
            cursor: pointer;
        }
    }
}

