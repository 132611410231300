.spinner {
    width: 100%;
    height: 100%;
}

.update-text {
  font-family: TTNormRegular, sans-serif;
  font-size: 18px;
}

.update-label {
  font-family: TTNormRegular, sans-serif;
  font-size: 18px;
}

.delete-button {
  font-family: TTNormRegular, sans-serif;
  font-size: 18px;
  border: 1px solid white;
  border-radius: 5px;
  color: white;
  background-color: #4b82ff;
  margin-bottom: 20px;
  height: 35px;
}
