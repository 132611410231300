.form-wrapper-field {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 347px;
  margin-bottom: 20px;
}

.form-field {
  width: 100%;
  margin-bottom: 20px;
  position: relative;
}

.green-button.upload-btn {
  width: 200px;
  height: 40px;
  font-size: 14px;
  color: white;
  background-color: #4b82ff;
  &:hover {
    cursor: pointer;
  }
}

.green-button.disabled {
  background-color: rgba(grey, 0.6);
}

.file-uploader_wrapper {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  width: 347px;
}

@media (min-width: 700px) {
  .form-wrapper-field {
    width: 422px;
  }
  .file-uploader_wrapper {
    width: 422px;
  }
  .notification-message {
    width: 422px;
  }
}