.connection-indicator {
  &-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
  }
  &-text-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px 5px;
    background-color: white;
    box-shadow: 0 4px 7px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    & p {
      font-size: 14px;
      font-family: TTNormRegular, sans-serif;
    }
  }
}