.alert-wrapper {
  width: min(100%, 450px);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  padding: 10px 10px;
  flex-direction: column;
  gap: 10px;
  z-index: 100;
}