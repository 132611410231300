.links {
    color: blue;
    &:hover {
        cursor: pointer;
    }
}

.flower-objects {
    margin-top: 20px;
    width: 90%;
}

p {
    margin: 5px 0px;
}

.myAccordion {
    background-color: red;
}

h4 {
    margin: 10px 0px 10px 0px;
}