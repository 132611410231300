.green-button {
    position: relative;
    font-family: TTNormRegular, sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    background: #73C120;
    width: 100%;
    height: 48px;
    border: none;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    color: white;
    text-decoration: none;
    margin-bottom: -15px;
    &:hover {
        opacity: 0.8;
        cursor: pointer;
    }
    &.disable {
        background: grey;
        opacity: 0.8;
        &:hover {
            cursor: default;
        }
    }
}

.help-icon {
    position: absolute !important;
    right: 10px;
    &.white {
        color: white;
    }
}
