.active-voice {
  width: 27px;
  height: 25px;
  display: flex;
  flex-direction: row;
  gap: 2px;
  align-items: center;
  & div {
    width: 2px;
    border-radius: 1px;
    height: 20px;
    background-color: #73C120;
  }
  & div:nth-child(1) {
    height: 4px;
    animation: firstAndLastChildren 1.2s infinite;
  }
  & div:nth-child(2) {
    height: 8px;
    animation: secondAndPreLastChildren 1.2s infinite;
  }
  & div:nth-child(3) {
    height: 12px;
    animation: preCenterChildren 1.2s infinite;
  }
  & div:nth-child(4) {
    height: 20px;
    animation: centerChildren 1.2s infinite;
  }
  & div:nth-child(5) {
    height: 12px;
    animation: preCenterChildren 1.2s infinite;
  }
  & div:nth-child(6) {
    height: 8px;
    animation: secondAndPreLastChildren 1.2s infinite;
  }
  & div:nth-child(7) {
    height: 4px;
    animation: firstAndLastChildren 1.2s infinite;
  }
}

@keyframes firstAndLastChildren {
  0% {height: 4px;}
  50% {height: 20px;}
  100% {height: 4px;}
}

@keyframes secondAndPreLastChildren {
  0% {height: 8px;}
  50% {height: 12px;}
  100% {height: 8px;}
}

@keyframes preCenterChildren {
  0% {height: 12px;}
  50% {height: 8px;}
  100% {height: 12px;}
}

@keyframes centerChildren{
  0% {height: 20px;}
  50% {height: 4px;}
  100% {height: 20px;}
}